.notification {
  left: auto !important;
  right: 0.5rem !important;
  top: 0.5rem !important;
  bottom: unset !important;
}

@media screen and (max-width: 600px) {
  .notification {
    left: auto !important;
    right: 1rem !important;
    top: unset !important;
    bottom: 4.5rem !important;
    max-width: calc(100vw - 2rem);
  }
}
