.main-container {
  padding-left: 2px !important;
  padding-right: 2px !important;

  .booking-container {
    display: flex;
    width: 100%;

    .booking-component {
      width: 100%;
      margin-bottom: 2rem;

      &.with-quote {
        margin-top: 3rem;
      }
    }

    .booking-buttons {
      display: flex;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 1023px) {
  .step-label {
    display: none !important;
  }
}

.action-button {
  background-color: var(--red-primary) !important;
  color: var(--light-primary) !important;
}

.error-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner {
  display: flex;
  position: fixed;
  flex-direction: row;
  width: 100%;
  left: 0;
  padding: 0.5rem 5rem;
  text-align: center;
  background-color: var(--banner-bg-color);
  border: 1px solid var(--dark-secondary);

  &__title {
    flex: 1;
    font-size: 0.9rem;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  &__close {
    width: 5rem;
    .close-icon {
      opacity: 0.7;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}

.bg-transparent {
  background-color: rgba(255, 255, 255, 0.85);
}
