body {
  background-color: var(--color-primary);
  color: var(--color-secondary);
}

.step-container {
  padding: 1rem;
  padding-top: 0;
}

.pt-1 {
  padding-top: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 1rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 1rem;
}
.mt-4 {
  margin-top: 1.5rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 1rem;
}
.mb-4 {
  margin-bottom: 1.5rem;
}

.MuiInputLabel-formControl {
  display: flex;
}

.MuiSelect-select {
  display: flex !important;
}

.MuiPickersLayout-actionBar {
  button {
    color: var(--dark-middle) !important;
  }
}

a {
  color: var(--red-primary);
}

.MuiFormControl-root
  > .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.Mui-focused:not(
    .Mui-error
  ) {
  color: unset;
}

.MuiPickersLayout-root {
  .MuiDateRangeCalendar-monthContainer {
    width: 100%;
  }
}
.MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight {
  background-color: var(--light-secondary);
}
