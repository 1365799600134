:root {
  --primary: #004699;
  --text-primary: #004699;
  --dark-primary: #333;
  --dark-secondary: #666666;
  --dark-light: #b9b4b4ad;
  --banner-bg-color: #f5f5f5c7;
  --light-primary: #ffffff;
  --light-secondary: #f5f5f5;
  --red-primary: #bf5600;
  --product-price: #f68b1f;
}
