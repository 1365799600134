.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  z-index: 99999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}
